.download-tooltip {
  background-color: rgba(14, 18, 113, 0.2) !important; }

.fleehealth-tooltip {
  background-color: #0e1271 !important; }

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  scroll-behavior: smooth;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.table-striped, tbody, tr:nth-of-type(even) {
  background-color: rgba(43, 113, 190, 0.1);
}
